<template>
  <div class="base-restaurant-card">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.base-restaurant-card {
  @apply overflow-hidden;
  @apply h-[276px] lg:h-[372px];
}
</style>
